import React from "react"
import Popup from "reactjs-popup";

import { Helmet } from 'react-helmet';

import './index.css';

import '../assets/fonts/font.css';

import PedagoLogo from '../images/logo.inline.svg';

import {
  isBrowser,
  isMobile,
  browserName,
  isMobileOnly
} from "react-device-detect";


class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenOrientation : undefined,
    }

    this.returnToPedago = this.returnToPedago.bind(this);
  }

  componentDidMount() {
    this.orientationListener();
    window.addEventListener("orientationchange", this.orientationListener);
  }
  componentWillUnmount() {
    sessionStorage.clear();
    window.removeEventListener("orientationchange", this.orientationListener);
  }

  orientationListener = () => {
    if ((window.orientation == 0) || (window.orientation) == 180) {
      this.setState({ screenOrientation: 'portait' })
    } else {
      this.setState({ screenOrientation: 'landscape' })
    }
  }

  returnToPedago() {
    console.log("REDIRECTION");
    document.location.href='https://pedagotech.com';
  }

  render(){
    var finalPopupCLassName = null;
    if (isMobileOnly) {
      if (this.state.screenOrientation == 'landscape') {
        finalPopupCLassName = "finalPopup-landscape"
      } else {
        finalPopupCLassName = "finalPopup-portrait";
      }
    } else {
      finalPopupCLassName = "finalPopup";
    }
    return(
      <div>
        <Helmet title="Pedagotech Stream" defer={false} />
        <div>
          <PedagoLogo className="pedagoLogo"/>
        </div>
        <Popup className={finalPopupCLassName} open={true} closeOnDocumentClick={false} closeOnDocumentClick={false} closeOnEscape={false} modal position="right center">
          <div>
            <div className="title"><div className="titleText">Visiblement cette page n’existe pas…<br/>Vérifiez l’adresse et retenter :)</div></div>
            <div className="bottom">
              <button className="btn1" onClick={this.returnToPedago}>
                Retourner sur votre pedagotech
              </button>
            </div>
          </div>
        </Popup>
      </div>
    );
  }


}

export default ErrorPage
